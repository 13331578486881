import * as SocialImageCreator from "./index";

export class SnapShot {
    image!: HTMLImageElement

    coordinates: SocialImageCreator.coordinates = {
        x: 0,
        y: 0,
        width: 100,
        height: 50
    }

    transform: SocialImageCreator.transform = {
        hscale: 1,
        hskew: 0,
        vskew: 0,
        vscale: 1,
        hmov: 0,
        vmov: 0
    }

    rotate = 0

    set src(src: HTMLImageElement['src']) {
        this.image = new Image()
        this.image.src = src
    }
}