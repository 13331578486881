import * as SocialImageCreator from "./index";

export class SocialImage {
  /** @ignore */
  #canvas!: HTMLCanvasElement;

  /* ignore */
  #backgroundImage!: HTMLImageElement;
  /* ignore */
  #topLayer!: HTMLImageElement;

  canvasId: HTMLCanvasElement["id"] = "SocialImageCanvas";
  containerId: HTMLElement["id"] = "SocialImageContainer";

  snapShots: { [key: number]: SocialImageCreator.SnapShot } = {};

  time!: SocialImageCreator.time;

  snapShotFilter: string = "none";

  #width!: number;
  #height!: number;

  set backgroundImage(src: HTMLImageElement["src"]) {
    this.#backgroundImage = new Image();
    this.#backgroundImage.src = src;
  }

  get backgroundImage() {
    return this.#backgroundImage.src;
  }

  set topLayer(src: HTMLImageElement["src"]) {
    this.#topLayer = new Image();
    this.#topLayer.src = src;
  }

  get topLayer() {
    return this.#topLayer.src;
  }

  async create() {
    // Wait for background and top layer images
    await this.#waitForImage(this.#backgroundImage);
    await this.#waitForImage(this.#topLayer);

    const container = document.getElementById(this.containerId) as HTMLElement;
    this.#canvas = document.getElementById(this.canvasId) as HTMLCanvasElement;

    // Check if the canvas exists, if not, create it
    if (container !== null && this.#canvas === null) {
      container.innerHTML += `<canvas id="${this.canvasId}" width="${
        this.#width
      }" height="${this.#height}"></canvas>`;
      this.#canvas = document.getElementById(
        this.canvasId
      ) as HTMLCanvasElement;
    }

    let ctx = this.#canvas.getContext("2d");
    if (ctx === null) return;

    ctx.clearRect(0, 0, this.#width, this.#height);

    // Draw background
    if (this.#backgroundImage !== undefined) {
      ctx.drawImage(this.#backgroundImage, 0, 0, this.#width, this.#height);
    }

    // Ensure all snapshot images are loaded
    const loadSnapshotImages = Object.values(this.snapShots).map((snapShot) =>
      this.#waitForImage(snapShot.image)
    );
    await Promise.all(loadSnapshotImages);

    // Draw snapshots
    for (const [id, snapShot] of Object.entries(this.snapShots)) {
      if (snapShot.image !== undefined) {
        ctx.save();
        ctx.filter = this.snapShotFilter;
        ctx.translate(snapShot.coordinates.x, snapShot.coordinates.y);
        ctx.rotate((snapShot.rotate * Math.PI) / 180);
        ctx.translate(-snapShot.coordinates.x, -snapShot.coordinates.y);
        ctx.transform(
          snapShot.transform.hscale,
          snapShot.transform.hskew,
          snapShot.transform.vskew,
          snapShot.transform.vscale,
          snapShot.transform.hmov,
          snapShot.transform.vmov
        );
        ctx.drawImage(
          snapShot.image,
          snapShot.coordinates.x,
          snapShot.coordinates.y,
          snapShot.coordinates.width,
          snapShot.coordinates.height
        );
        ctx.restore();
      }
    }

    // Draw additional elements like time and top layer
    if (this.time !== undefined) {
      ctx.font = this.time.fontSize;
      ctx.strokeText(this.time.text, this.time.left, this.time.top);
    }

    if (this.#topLayer !== undefined) {
      ctx.drawImage(this.#topLayer, 0, 0, this.#width, this.#height);
    }

    return true;
  }

  /* @ignore */
  #waitForImage = async (image: HTMLImageElement): Promise<void> => {
    if (!image) {
      return console.warn(`SIC Warning: Image ${image} is undefined.`);
    }

    return new Promise<void>((resolve, reject) => {
      if (image.complete) {
        resolve();
      } else {
        image.addEventListener("load", () => {
          resolve();
        });
        image.addEventListener("error", () => {
          reject(new Error(`Image: ${image} failed to load`));
        });
      }
    });
  };

  set width(width: number) {
    this.#width = width;
  }

  get width(): number {
    return (
      this.#width ||
      this.#backgroundImage.naturalWidth ||
      this.#topLayer.naturalWidth
    );
  }

  set height(height: number) {
    this.#height = height;
  }

  get height(): number {
    return (
      this.#height ||
      this.#backgroundImage.naturalHeight ||
      this.#topLayer.naturalHeight
    );
  }
}
